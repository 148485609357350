<template>
  <div>
    <!-- <b-row class="col-12">
        <b-button variant="primary" v-show="show_embed" v-on:click="show_color = !show_color" class="mt-2 mb-2">Toggle color picker</b-button>
        <b-button variant="ghost-danger" v-show="show_embed" v-on:click="resetEmbed" class="m-2">Clear</b-button>
        <b-button variant="primary" v-on:click="show_embed = !show_embed" class="mt-2 mb-2 ml-auto">{{show_embed == true ? 'Hide' : 'Show embed builder'}}</b-button>
    </b-row>-->

    <b-row class="mr-auto" v-if="show_embed">
      <b-col cols="auto" class="mr-auto ml-2 p-2" v-if="!disabled">
        <b-button
          variant="primary"
          v-show="show_embed"
          v-on:click="show_color = !show_color"
          class="mt-2 mb-2"
        >Toggle color picker</b-button>
        <b-button
          variant="ghost-primary"
          v-if="limits.image != 20000"
          v-on:click="increase_limits"
          class="mt-2 mb-2 mx-2"
        >Advanced</b-button>
        <b-button
          variant="ghost-primary"
          v-if="limits.image == 20000"
          v-on:click="show_input = !show_input"
          class="mt-2 mb-2 mx-2"
        >{{show_input ? 'Hide' : 'Import'}}</b-button>
        <b-button
          variant="ghost-primary"
          v-b-tooltip.hover
          title="Copy raw json to clipboard"
          delay="20"
          id="copypasted"
          v-if="limits.image == 20000"
          v-clipboard:copy="JSON.stringify(full_json)"
          class="mt-2 mb-2 mx-2"
        >Export</b-button>

        <b-form-group v-if="show_input">
          <b-input-group>
            <b-form-input
              show="true"
              :maxlength="100000"
              v-model="raw_json"
              type="text"
              placeholder="Raw JSON"
            ></b-form-input>
            <!-- Attach Right button -->
            <b-input-group-append>
              <b-button
                v-on:click="apply_json(JSON.parse(raw_json)); show_input=false; raw_json=''"
                variant="primary"
              >Apply</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="auto" class="p-2">
        <b-button
        v-if="!disabled"
          variant="ghost-danger"
          v-show="show_embed"
          v-on:click="resetEmbed"
          class="m-2"
        >Clear</b-button>
      </b-col>
      <b-col cols="auto" class="pt-2 pb-2">
        <b-button
          variant="primary"
          v-on:click="show_embed = !show_embed"
          class="mt-2 mb-2 ml-auto"
        >{{show_embed == true ? 'Hide' : 'Show embed builder'}}</b-button>
      </b-col>
    </b-row>
    <div v-else>
      <b-button
        variant="secondary"
        block
        v-on:click="show_embed = !show_embed"
        class="mt-2 mb-2 ml-auto"
      >{{show_embed == true ? 'Hide' : 'Show embed builder'}}</b-button>
    </div>
    <!-- <div v-show="show_embed" class="p-0" :class="{ disableFullForm: disabled }"> -->
    <div v-show="show_embed" v-bind:class = "(disabled === true)?'disableFullForm':'p-0'">
      <sketch-picker v-on-clickaway="hide_colors" v-if="show_color" v-model="new_embed.colors"></sketch-picker>
      <b-card id="embedbuilder" :style="{'border-left-color': embed_color}">
        <b-row class="form-group">
          <b-col md="3">
            <b-form-input
              :maxlength="limits.image"
              v-model="new_embed.icon_url"
              type="text"
              placeholder="Icon url"
            ></b-form-input>
          </b-col>
          <b-col md="6">
            <b-form-input
              :maxlength="limits.title"
              v-model="new_embed.name_header"
              type="text"
              placeholder="Name"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <b-form-input
              :maxlength="limits.title"
              v-model="new_embed.name_url"
              type="text"
              placeholder="Name url"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col md="9">
            <b-form-input
              :maxlength="limits.image"
              v-model="new_embed.title"
              type="text"
              placeholder="Title"
            ></b-form-input>
            <small>{{title_length}}/{{limits.image}}</small>
          </b-col>
          <b-col md="3">
            <b-form-input
              :maxlength="limits.image"
              v-model="new_embed.url"
              type="text"
              placeholder="Title url"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-textarea
              :maxlength="limits.description"
              rows="3"
              type="text"
              placeholder="Description"
              class="col-12"
              v-model="new_embed.description"
            ></b-form-textarea>

            <small>{{description_length}}/{{limits.description}}</small>
          </b-col>
        </b-row>
        <b-row v-for="(f, index) in new_embed.fields" :key="index" class="form-group">
          <b-col md="10">
            <b-form-input
              :maxlength="limits.title"
              v-model="new_embed.fields[index].name"
              type="text"
              :placeholder="'Title for field ' + (index + 1)"
            ></b-form-input>
            <small>{{f.name.length}}/{{limits.title}}</small>
          </b-col>
          <b-col md="2">
            <label :for="'checkbox'+index" class="p-2">
              <b-form-checkbox
                :id="'checkbox'+index"
                v-model="new_embed.fields[index].inline"
              >Inline</b-form-checkbox>
            </label>
          </b-col>
          <b-col md="11">
            <b-form-textarea
              :maxlength="limits.field"
              rows="3"
              type="text"
              :placeholder="'Description for field ' + (index + 1)"
              class="col-12"
              v-model="new_embed.fields[index].value"
            ></b-form-textarea>
            <small>{{f.value.length}}/{{limits.field}}</small>
          </b-col>
          <b-col md="1">
            <b-button
              variant="ghost-danger"
              v-b-tooltip.hover
              title="Remove field"
              v-on:click="new_embed.fields.splice(index, 1)"
            >
              <font-awesome-icon icon="times" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="form-group" v-if="new_embed.fields.length < 25">
          <b-col md="12">
            <b-button
              size="md"
              variant="secondary"
              block
              v-on:click="new_embed.fields.push({name: '', value: '', inline: false})"
            >Add field</b-button>
            <small>{{new_embed.fields.length}}/25 fields</small>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col md="6">
            <b-form-input
              :maxlength="limits.image"
              v-model="new_embed.image_url"
              type="text"
              placeholder="Image url (big at the bottom)"
            ></b-form-input>
          </b-col>
          <b-col md="6">
            <b-form-input
              :maxlength="limits.image"
              v-model="new_embed.thumbnail_url"
              type="text"
              placeholder="Thumbnail url (small top right)"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col md="4">
            <b-form-input
              :maxlength="limits.description"
              v-model="new_embed.footer_text"
              type="text"
              placeholder="Footer"
            ></b-form-input>
            <small>{{footer_text_length}}/{{limits.description}}</small>
          </b-col>
          <b-col md="3">
            <b-form-input
              :maxlength="limits.image"
              v-model="new_embed.footer_icon"
              type="text"
              placeholder="Footer icon"
            ></b-form-input>
          </b-col>
          <b-col md="5">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="icon-calendar"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <flat-pickr
                v-model="new_embed.timestamp"
                :config="config"
                class="form-control"
                placeholder="Select date"
                name="date"
              ></flat-pickr>
              <b-input-group-append>
        <b-button @click="clearDate" variant="danger">Clear</b-button>
      </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import miniToastr from "mini-toastr";
import { Sketch } from "vue-color";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/themes/dark.css";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "EmbedBuilder",
  components: {
    "sketch-picker": Sketch,
    flatPickr
  },
  mixins: [clickaway],
  data: function() {
    return {
      colors: { hex: "#4f545c" },
      show_color: false,
      show_embed: false,
      config: {
        altInput: true,
        enableTime: true,
        altFormat: "F j, Y H:i",
        dateFormat: "Z",
        time_24hr: true
      },
      message_content: "",
      channels: [],
      channel: null,
      new_embed: { fields: [], colors: { hex: "#4f545c" } },
      limits: {
        image: 200,
        title: 256,
        field: 1024,
        description: 2048
      },
      show_input: false
    };
  },
  methods: {
    hide_colors() {
      if (this.show_color) {
        this.show_color = false;
      }
    },
    resetEmbed() {
      this.new_embed = { fields: [], colors: { hex: "#4f545c" } };
    },
    increase_limits() {
      this.limits = {
        image: 20000,
        title: 20000,
        field: 20000,
        description: 20000
      };
    },
    apply_json(data) {
      this.new_embed = {};
      this.new_embed = {
        ...data,
        ...{
          fields: data.fields == undefined ? [] : data.fields,
          colors: {
            hex:
              data.color == undefined
                ? "#4f545c"
                : "#" + data.color.toString(16).padStart(6, "0")
          },
          ...{
            name_header:
              data.author == undefined ? undefined : data.author.name,
            icon_url:
              data.author == undefined ? undefined : data.author.icon_url,
            name_url: data.author == undefined ? undefined : data.author.url,
            footer_text:
              data.footer == undefined ? undefined : data.footer.text,
            footer_icon:
              data.footer == undefined ? undefined : data.footer.icon_url,
            thumbnail_url:
              data.thumbnail == undefined ? undefined : data.thumbnail.url,
            image_url: data.image == undefined ? undefined : data.image.url
          }
        }
      };
      this.$forceUpdate();
    },
    clearDate() {
      this.new_embed.timestamp = null;
    }
  },
  computed: {
    full_json() {
      var just_embed = {};
      if (this.new_embed.url) {
        just_embed.url = this.new_embed.url;
      }
      if (this.new_embed.type) {
        just_embed.type = this.new_embed.type;
      }
      if (this.new_embed.fields.length !== 0) {
        just_embed.fields = this.new_embed.fields;
      }
      if (this.new_embed.timestamp) {
        just_embed.timestamp = this.new_embed.timestamp;
      }
      if (this.new_embed.title) {
        just_embed.title = this.new_embed.title;
      }
      if (this.new_embed.description) {
        just_embed.description = this.new_embed.description;
      }
      if (this.new_embed.thumbnail_url) {
        just_embed.thumbnail = { url: this.new_embed.thumbnail_url };
      }
      if (this.new_embed.image_url) {
        just_embed.image = { url: this.new_embed.image_url };
      }
      if (this.new_embed.image_url) {
        just_embed.image = { url: this.new_embed.image_url };
      }
      if (this.new_embed.name_header) {
        just_embed.author = {
          name: this.new_embed.name_header,
          url: this.new_embed.name_url,
          icon_url: this.new_embed.icon_url
        };
      }
      if (this.new_embed.colors) {
        just_embed.color = parseInt(this.new_embed.colors.hex.substring(1), 16);
      }
      if (this.new_embed.footer_text || this.new_embed.footer_icon) {
        just_embed.footer = {
          icon_url: this.new_embed.footer_icon,
          text: this.new_embed.footer_text
        };
      }
      if (this.new_embed.footer_icon && !this.new_embed.footer_text) {
        just_embed.footer = {
          icon_url: this.new_embed.footer_icon,
          text: '\u200B'
        };
      }
      return just_embed;
    },
    sorted_channels() {
      return this.channels.sort((a, b) => a.position - b.position);
    },
    title_length() {
      return this.new_embed.title == undefined
        ? 0
        : this.new_embed.title.length;
    },
    description_length() {
      return this.new_embed.description == undefined
        ? 0
        : this.new_embed.description.length;
    },
    footer_text_length() {
      return this.new_embed.footer_text == undefined
        ? 0
        : this.new_embed.footer_text.length;
    },
    embed_color() {
      return this.new_embed.colors.hex;
    }
  },
  props: ['disabled']

};
</script>
<style>
#embedbuilder {
  border-left-width: 4px;
}

.wrapper {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
.disableFullForm{
    pointer-events: none;
}
</style>
