<template>
  <div class="animated">
    <b-card>
      <div slot="header">
        <i class="icon-emotsmile"></i>
        Reaction Roles
      </div>

      <b-button variant="success" v-on:click="openCreateModal">
        <font-awesome-icon icon="plus" class="mr-2" />Create new reaction role
      </b-button>
      <hr />
      <div v-if="loading">
        <div class="sk-circle-fade float-left">
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
          <div class="sk-grid-cube"></div>
        </div>
        <h2 class="float-left ml-4">Loading reaction roles...</h2>
      </div>
      <v-client-table
        :columns="columns"
        :data="reactionroles"
        :options="options"
        :theme="theme"
        id="dataTable"
        style="width: 100%"
        v-else
      >
        <p
          slot="channel"
          class="d-md-inline-block"
          slot-scope="props"
          style="max-width: 780px !important"
          block
        >
          #{{ channel_from_id(props.row.messages[0].channel_id).name }}
        </p>
        <p
          slot="roles"
          class="d-md-inline-block"
          slot-scope="props"
          style="max-width: 780px !important"
          block
        >
          {{
            props.row.messages.map(x => role_from_id(x.role_id).name).join(", ")
          }}
        </p>
        <p
          slot="type"
          class="d-md-inline-block"
          slot-scope="props"
          style="max-width: 780px !important"
          block
        >
          {{ props.row.extra ? props.row.extra.type : "normal" }}
        </p>

        <b-button
          slot="actions"
          slot-scope="props"
          block
          variant="outline-primary"
          v-on:click="openEditModal(props.row)"
          >Edit</b-button
        >
      </v-client-table>
    </b-card>
    <b-modal
      size="lg"
      v-model="largeModal"
      @ok="largeModal = false"
      :no-enforce-focus="true"
    >
      <template slot="modal-title">
        <h4 class="smalltitle">
          <i
            :class="
              'fa fa-' +
                [
                  'tired',
                  'surprise',
                  'smile-wink',
                  'smile-beam',
                  'sad-tear',
                  'sad-cry',
                  'meh-rolling-eyes',
                  'meh-blank',
                  'laugh-squint',
                  'laugh-beam',
                  'laugh',
                  'kiss-wink-heart',
                  'kiss-beam',
                  'kiss',
                  'grin-tongue-wink',
                  'grin-tongue-squint',
                  'grin-tongue',
                  'grin-tears',
                  'grin-stars',
                  'grin-squint-tears',
                  'grin-squint',
                  'grin-hearts',
                  'grin-beam-sweat',
                  'grin-beam',
                  'grin-alt',
                  'grin',
                  'grimace',
                  'frown-open',
                  'flushed',
                  'angry',
                  'smile'
                ][Math.floor(Math.random() * 31)]
            "
            style="margin-right:10px"
          ></i
          >Edit reaction role
        </h4>
      </template>
      <h4 class="smalltitle">Reaction roles</h4>
      <p v-if="readonly_alert" :style="{'color':'#f54242'}"> The content of this reaction role message can not be edited because Carl-bot is not the author. </p>
      <p v-if="errorAlert" :style="{'color':'#f54242'}"> {{this.errorMessage}} </p>
      <h4 v-if="create_mode === 0" class="smalltitle">Content</h4>
          <b-input-group class="edit-rrmessage">
            <b-form-textarea
              v-if="create_mode === 0"
              rows="6"
              type="text"
              pattern="[a-z0-9-_]{1,5000}"
              placeholder="Enter some content"
              class="col-12"
              v-model="rr_content"
              :disabled="readonly"
            ></b-form-textarea>
          </b-input-group>
          <hr />
      <b-form-group>
      <div>
      <EmbedBuilder
            v-show="true"
            ref="embed_builder"
            v-bind:disabled="readonly"
          ></EmbedBuilder>
          </div>
        <div v-for="(row, idx) in selected_reactions" :key="row.emoji.id">
          <div class="flexContainer" style="margin-bottom:8px;">
            <div class="toolsContainer">
              <div class="closebutton" @click="splice_selected(idx)">
                <svg
                  name="Close"
                  aria-hidden="true"
                  width="22"
                  height="22"
                  viewBox="0 0 12 12"
                >
                  <g fill="none" fill-rule="evenodd" aria-hidden="true">
                    <path d="M0 0h12v12H0" />
                    <path
                      class="fill"
                      fill="#dcddde"
                      d="M9.5 3.205L8.795 2.5 6 5.295 3.205 2.5l-.705.705L5.295 6 2.5 8.795l.705.705L6 6.705 8.795 9.5l.705-.705L6.705 6"
                    />
                  </g>
                </svg>
              </div>
              <span v-if="row.emoji.custom" style="padding:0px 12px;">
                <img :src="row.emoji.imageUrl" width="32" height="32" />
              </span>

              <emoji
                v-else
                class="displayed-emoji"
                set="twitter"
                :data="index"
                :emoji="row.emoji"
                :size="32"
              />
            </div>
          </div>

          <b-form-group :label-for="'thing_' + 'idx'">
            <b-input-group>
              <multiselect
                v-model="row.roles"
                :options="usable_roles"
                :multiple="true"
                label="name"
                track-by="id"
                :id="'thing_' + 'idx'"
              >
                <template slot="tag" slot-scope="{ option, search, remove }">
                  <span
                    class="multiselect__tag"
                    :style="{
                      'border-color': option
                        ? '#' + option.color.toString(16)
                        : '#eeaaee'
                    }"
                  >
                    <i
                      aria-hidden="true"
                      tabindex="1"
                      @keydown.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon fakediscord"
                      :style="{
                        'background-color': '#' + intToHex(option.color || 0)
                      }"
                    ></i>
                    <span class="defaultcursor" v-text="option.name"></span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div
                    :style="{ color: parseColor(props.option) }"
                    class="option__desc"
                  >
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </b-input-group>
          </b-form-group>
        </div>
        <hr />
        <b-button
          variant="success"
          v-if="selected_reactions.length < 20"
          block
          v-on:click="showpicker = !showpicker"
          >Add emoji</b-button
        >
        <keep-alive>
          <picker
            @select="this.selectEmoji"
            :custom="custom"
            :data="index"
            v-if="showpicker"
            emoji="turtle"
            set="twitter"
            title="Carl bot"
            :emojiTooltip="false"
            style="position:absolute;z-index:5000"
            :showSkinTones="false"
          />
        </keep-alive>
      </b-form-group>
      <hr />
      <b-form-group>
        <label class="teenytinytitle" for="type_select">Message type</label>
        <b-form-select
          id="type_select"
          v-model="reaction_type"
          value="Please select"
        >
          <option
            v-for="t in reaction_types"
            :disabled="t.name == 'temp' && !is_patron"
            :key="t.name"
            :value="t"
            >{{ t.name }}</option
          >
        </b-form-select>

        <b-input-group v-if="['limit', 'temp'].includes(reaction_type.name)">
          <b-form-input
            :placeholder="
              reaction_type.name == 'limit'
                ? 'Input your limit'
                : 'Input your delay'
            "
            style="margin-top:10px;"
            type="number"
            pattern="[0-9]{1,3}"
            class="col-12"
            min="0"
            v-on:keydown="handleOnlyNumberkeyInput"
            v-model="reaction_role_limit"
          ></b-form-input>
          <b-input-group-append v-if="reaction_type.name === 'temp'">
            <b-input-group-text class="mr-1" style="margin-top:10px;"
              >Minutes</b-input-group-text
            >
          </b-input-group-append>
        </b-input-group>
        {{ reaction_type.description }}
      </b-form-group>
      <hr />
      <b-form-group>
        <label class="teenytinytitle" for="whitelist"
          >Only allow members with any of these roles to pick up roles</label
        >
        <multiselect
          v-model="whitelist"
          :options="roles"
          :multiple="true"
          label="name"
          track-by="id"
          id="whitelist"
        >
          <template slot="tag" slot-scope="{ option, search, remove }">
            <span
              class="multiselect__tag"
              :style="{
                'border-color': option
                  ? '#' + option.color.toString(16)
                  : '#eeaaee'
              }"
            >
              <i
                aria-hidden="true"
                tabindex="1"
                @keydown.enter.prevent="remove(option)"
                @mousedown.prevent="remove(option)"
                class="multiselect__tag-icon fakediscord"
                :style="{
                  'background-color': '#' + intToHex(option.color || 0)
                }"
              ></i>
              <span class="defaultcursor" v-text="option.name"></span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div
              :style="{ color: parseColor(props.option) }"
              class="option__desc"
            >
              <span class="option__title">{{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group>
        <label class="teenytinytitle" for="blacklist"
          >Blacklist members with any of these roles from picking up reaction
          roles</label
        >
        <multiselect
          v-model="blacklist"
          :options="roles"
          :multiple="true"
          label="name"
          track-by="id"
          id="blacklist"
        >
          <template slot="tag" slot-scope="{ option, search, remove }">
            <span
              class="multiselect__tag"
              :style="{
                'border-color': option
                  ? '#' + option.color.toString(16)
                  : '#eeaaee'
              }"
            >
              <i
                aria-hidden="true"
                tabindex="1"
                @keydown.enter.prevent="remove(option)"
                @mousedown.prevent="remove(option)"
                class="multiselect__tag-icon fakediscord"
                :style="{
                  'background-color': '#' + intToHex(option.color || 0)
                }"
              ></i>
              <span class="defaultcursor" v-text="option.name"></span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div
              :style="{ color: parseColor(props.option) }"
              class="option__desc"
            >
              <span class="option__title">{{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </b-form-group>

      <div slot="modal-footer" class="w-100">
        <b-container
          fluid
          class="text-light text-center"
          style="padding-right:0px;"
        >
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2">
              <b-button
                v-b-tooltip.hover
                title="Delete reaction roles"
                variant="ghost-danger"
                v-on:click="deleteRR"
              >
                <i class="icon-trash"></i>
              </b-button>
            </b-col>

            <b-col cols="auto" class="p-2">
              <b-button
                variant="ghost-secondary"
                v-on:click="largeModal = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button v-if="!errorAlert" variant="primary" v-on:click="patchRR">Save</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal
      title="Create reaction role"
      size="lg"
      v-model="createModal"
      @ok="xd = false"
      :no-enforce-focus="true"
    >
      <template slot="modal-title">
        <h4 class="smalltitle">
          <i
            :class="
              'fa fa-' +
                [
                  'tired',
                  'surprise',
                  'smile-wink',
                  'smile-beam',
                  'sad-tear',
                  'sad-cry',
                  'meh-rolling-eyes',
                  'meh-blank',
                  'laugh-squint',
                  'laugh-beam',
                  'laugh',
                  'kiss-wink-heart',
                  'kiss-beam',
                  'kiss',
                  'grin-tongue-wink',
                  'grin-tongue-squint',
                  'grin-tongue',
                  'grin-tears',
                  'grin-stars',
                  'grin-squint-tears',
                  'grin-squint',
                  'grin-hearts',
                  'grin-beam-sweat',
                  'grin-beam',
                  'grin-alt',
                  'grin',
                  'grimace',
                  'frown-open',
                  'flushed',
                  'angry',
                  'smile'
                ][Math.floor(Math.random() * 31)]
            "
            style="margin-right:10px"
          ></i
          >Create reaction role
        </h4>
      </template>
      <b-form-group>
        <h4 class="smalltitle">Mode</h4>
        <b-form-group>
          <b-form-select
            id="server_channel_select"
            v-model="create_mode"
            value="Please select"
          >
            <option
              v-for="t in creation_modes"
              :key="t.value"
              :value="t.value"
              >{{ t.name }}</option
            >
          </b-form-select>
          <b-form-input
            v-if="create_mode === 1"
            placeholder="Please enter your message ID"
            style="margin-top:10px;"
            type="number"
            pattern="[0-9]{10,30}"
            class="col-12"
            v-model="message_id"
            min="0"
            v-on:keydown="handleOnlyNumberkeyInput"
          ></b-form-input>
          <multiselect
            v-model="reaction_channel"
            id="discord_channel_select"
            track-by="id"
            label="name"
            placeholder="Please select a channel"
            open-direction="bottom"
            :searchable="true"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="groupedChannels"
            :allow-empty="false"
            group-values="channels"
            group-label="category"
            :group-select="false"
            style="margin-top:10px;"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color:#72767d" icon="hashtag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <font-awesome-icon
                    style="color:#72767d"
                    :icon="
                      !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                    "
                  />
                  {{ props.option.name || props.option.$groupLabel.name }}
                </span>
              </div>
            </template>
          </multiselect>
          <hr v-if="create_mode === 0" />
          <h4 v-if="create_mode === 0" class="smalltitle">Content</h4>
          <b-input-group>
            <b-form-textarea
              v-if="create_mode === 0"
              rows="6"
              type="text"
              pattern="[a-z0-9-_]{1,5000}"
              placeholder="Enter some content"
              class="col-12"
              v-model="rr_content"
            ></b-form-textarea>
          </b-input-group>
          <hr />
          <EmbedBuilder
            v-show="create_mode === 0"
            ref="embed_builder"
          ></EmbedBuilder>
          <div v-for="(row, index) in selected_reactions" :key="row.emoji.id">
            <div class="flexContainer" style="margin-bottom:8px;">
              <div class="toolsContainer">
                <div class="closebutton" @click="splice_selected(index)">
                  <svg
                    name="Close"
                    aria-hidden="true"
                    width="22"
                    height="22"
                    viewBox="0 0 12 12"
                  >
                    <g fill="none" fill-rule="evenodd" aria-hidden="true">
                      <path d="M0 0h12v12H0" />
                      <path
                        class="fill"
                        fill="#dcddde"
                        d="M9.5 3.205L8.795 2.5 6 5.295 3.205 2.5l-.705.705L5.295 6 2.5 8.795l.705.705L6 6.705 8.795 9.5l.705-.705L6.705 6"
                      />
                    </g>
                  </svg>
                </div>
                <span v-if="row.emoji.custom" style="padding:0px 12px;">
                  <img :src="row.emoji.imageUrl" width="32" height="32" />
                </span>

                <emoji
                  v-else
                  class="displayed-emoji"
                  set="twitter"
                  :data="index"
                  :emoji="row.emoji"
                  :size="32"
                />
              </div>
            </div>
            <b-form-group :label-for="index + ':thing'">
              <b-input-group>
                <multiselect
                  v-model="row.roles"
                  :options="usable_roles"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  :id="index + ':thing'"
                >
                  <template slot="tag" slot-scope="{ option, search, remove }">
                    <span
                      class="multiselect__tag"
                      :style="{
                        'border-color': option
                          ? '#' + option.color.toString(16)
                          : '#eeaaee'
                      }"
                    >
                      <i
                        aria-hidden="true"
                        tabindex="1"
                        @keydown.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon fakediscord"
                        :style="{
                          'background-color': '#' + intToHex(option.color || 0)
                        }"
                      ></i>
                      <span class="defaultcursor" v-text="option.name"></span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div
                      :style="{ color: parseColor(props.option) }"
                      class="option__desc"
                    >
                      <span class="option__title">{{ props.option.name }}</span>
                    </div>
                  </template>
                </multiselect>
              </b-input-group>
            </b-form-group>
          </div>
          <hr />
          <b-button
            variant="success"
            v-if="selected_reactions.length < 20"
            block
            v-on:click="showpicker = !showpicker"
            >Add emoji</b-button
          >
          <keep-alive>
            <picker
              @select="this.selectEmoji"
              :custom="custom"
              :data="index"
              v-if="showpicker"
              emoji="turtle"
              set="twitter"
              title="Carl bot"
              :emojiTooltip="false"
              style="position:absolute;z-index:5000"
              :showSkinTones="false"
            />
          </keep-alive>
        </b-form-group>
        <hr />
        <b-form-group>
          <label class="teenytinytitle" for="type_select">Message type</label>
          <b-form-select
            id="type_select"
            v-model="reaction_type"
            value="Please select"
          >
            <option
              v-for="t in reaction_types"
              :disabled="t.name == 'temp' && !is_patron"
              :key="t.name"
              :value="t"
              >{{ t.name }}
              {{
                !is_patron && t.name == "temp" ? "(premium only)" : ""
              }}</option
            >
          </b-form-select>
          <b-input-group v-if="['limit', 'temp'].includes(reaction_type.name)">
            <b-form-input
              :placeholder="
                reaction_type.name == 'limit'
                  ? 'Input your limit'
                  : 'Input your delay'
              "
              style="margin-top:10px;"
              type="number"
              pattern="[0-9]{1,3}"
              class="col-12"
              v-model="reaction_role_limit"
              min="0"
              v-on:keydown="handleOnlyNumberkeyInput"
            ></b-form-input>
            <b-input-group-append v-if="reaction_type.name === 'temp'">
              <b-input-group-text class="mr-1" style="margin-top:10px;">{{
                reaction_role_limit == 1 ? "Minute" : "Minutes"
              }}</b-input-group-text>
            </b-input-group-append>
          </b-input-group>
          {{ reaction_type.description }}
        </b-form-group>
        <hr />
        <b-form-group>
          <label class="teenytinytitle" for="whitelist"
            >Only allow members with any of these roles to pick up roles</label
          >
          <multiselect
            v-model="whitelist"
            :options="roles"
            :multiple="true"
            label="name"
            track-by="id"
            id="whitelist"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag"
                :style="{
                  'border-color': option
                    ? '#' + option.color.toString(16)
                    : '#eeaaee'
                }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon fakediscord"
                  :style="{
                    'background-color': '#' + intToHex(option.color || 0)
                  }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div
                :style="{ color: parseColor(props.option) }"
                class="option__desc"
              >
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group>
          <label class="teenytinytitle" for="blacklist"
            >Blacklist members with any of these roles from picking up reaction
            roles</label
          >
          <multiselect
            v-model="blacklist"
            :options="roles"
            :multiple="true"
            label="name"
            track-by="id"
            id="blacklist"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag"
                :style="{
                  'border-color': option
                    ? '#' + option.color.toString(16)
                    : '#eeaaee'
                }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon fakediscord"
                  :style="{
                    'background-color': '#' + intToHex(option.color || 0)
                  }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div
                :style="{ color: parseColor(props.option) }"
                class="option__desc"
              >
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <hr />
      </b-form-group>
      <div slot="modal-footer" class="w-100">
        <b-container
          fluid
          class="text-light text-center"
          style="padding-right:0px;"
        >
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button
                variant="ghost-secondary"
                v-on:click="createModal = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="success" v-on:click="createRR"
                >Create</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import EmbedBuilder from "@/views/oc/EmbedBuilder";
import Multiselect from "vue-multiselect";
import data from "emoji-mart-vue-fast/data/twitter.json";
import { Picker, EmojiIndex, Emoji } from "emoji-mart-vue-fast";
import _ from 'lodash';

import "emoji-mart-vue-fast/css/emoji-mart.css";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};

const CUSTOM = [];

let index = new EmojiIndex(data, {
  custom: CUSTOM
});
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);
Vue.use(ClientTable);

export default {
  name: "DataTable",
  components: {
    ClientTable,
    Event,
    cSwitch,
    EmbedBuilder,
    Multiselect,
    Picker,
    Emoji
  },
  notifications: {
    bad_input: {
      type: VueNotifications.types.warn,
      title: "Uh oh",
      message: "Please fill in all fields"
    }
  },
  data: function() {
    return {
      columns: ["channel", "roles", "type", "actions"],
      custom_emoji: [],
      reactionroles: [],
      readonly: true,
      readonly_alert: false,
      errorAlert: false,
      errorMessage: "",
      roles: [],
      index: index,
      channels: [],
      custom: CUSTOM,
      xd: EmbedBuilder,
      selected_reactions: [],
      rr_content: "",
      content_flags: "",
      rr_old_data: "",
      options: {
        filterByColumn: true,
        columnsDisplay: {
          type: "not_mobile",
          roles: "min_tablet"
        },
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}"
        },
        headings: {
          name: "Name",
          code: "Content",
          uri: "View Record"
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: true,
          nav: "scroll"
        },
        childRow: false,
        highlightMatches: true
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      largeModal: false,
      showpicker: false,
      createModal: false,
      reaction_channel: null,
      editChannel: null,
      message_id: null,
      whitelist: [],
      top_position: 0,
      blacklist: [],
      create_mode: 0,
      is_patron: false,
      loading: true,
      creation_modes: [
        { name: "Post embed", value: 0 },
        { name: "Use ID", value: 1 },
        { name: "Use most recent message in channel", value: 2 }
      ],
      reaction_type: {
        name: "normal",
        description:
          "Hands out roles when you click on them, does what you'd expect"
      },
      reaction_types: [
        {
          name: "normal",
          description:
            "Hands out roles when you click on them, does what you'd expect"
        },
        {
          name: "unique",
          description:
            "Only lets one role from the message be picked up at once"
        },
        {
          name: "verify",
          description: "Roles can only be picked up, not removed"
        },
        {
          name: "drop",
          description: "Roles can only be removed, not picked up"
        },
        {
          name: "reversed",
          description:
            "Adding a reaction removes the role, removing the reaction adds a role"
        },
        {
          name: "limit",
          description:
            "Limits the total number of roles one can pick up from this message"
        },
        {
          name: "binding",
          description:
            "You can only choose one role and you can not swap between roles"
        },
        {
          name: "temp",
          description: "Roles are assigned for a limited time only."
        }
      ],
      reaction_role_limit: 0
    };
  },
  methods: {
    role_from_id(id) {
      return this.roles.find(x => x.id == id) || { name: "unknown role" };
    },
    emojiToHtml(emoji) {
      let style = `background-position: ${emoji.getPosition()}`;
      // The src="data:image..." is needed to prevent border around img tags.
      return `<img data-text="${emoji.native}" alt="${emoji.colons}" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class='emoji-text' style="${style}">`;
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16);
    },
    parseColor(r) {
      return r.color != null ? "#" + r.color.toString(16) : "#fefefe";
    },
    channel_from_id(id) {
      return this.channels.find(x => x.id == id) || { name: "unknown channel" };
    },
    emoji_from_id(id) {
    const numEmojisRegex = /[\u0030-\u0039\u20E3]/g;
    if (numEmojisRegex.test(id)){
    }
    else if (id.length > 2 && parseInt(id)) {
        id = this.index._custom.find(x => x.custom_id == id);
        if (id) {
          id = id.id;
        }
      }
      const maybe_emoji = this.index.findEmoji(id, null);
      if (!maybe_emoji) {
        if (id.length > 10) {
          return null;
        }
        return this.index.findEmoji(id[0] + "\ufe0f\u20e3", null);
      }
      return maybe_emoji;
    },
    openEditModal(row) {
    this.readonly_alert = false;
    this.errorAlert = false;
    this.errorMessage = "";
    this.rr_content = "";
    this.content_flags = "";
      this.axios
      .get("/api/v1/servers/" +
            this.$route.params.guild_id +
            "/reactionroles/" + row.messages[0].channel_id + "/" + row.message_id )
      .then(r => {
        if(r.status === 200 && r.data.status != "error"){
        this.readonly = r.data.readonly;
        this.rr_old_data = r.data;
        this.readonly_alert = r.data.readonly;
        this.rr_content = r.data.message['content'];
        this.content_flags = r.data.flags;
        if(r.data.message['embeds'].length != 0){
          this.$refs.embed_builder.show_embed = true;
          this.$refs.embed_builder.apply_json(r.data.message['embeds'][0]);
          // this.$refs.embed_builder.show_embed = true;
          }
        }
        else{
        this.readonly = true;
        this.errorAlert = true;
        this.errorMessage = r.data.error_message;
        }
      });
      const rowType = row.extra ? row.extra.type.split(" ")[0] : "";
      this.reaction_type = row.extra
        ? this.reaction_types.find(x => x.name == rowType)
        : {
            name: "normal",
            description:
              "Hands out roles when you click on them, does what you'd expect"
          };

      if (row.extra != null) {
        const [typ, num] = row.extra.type.split(" ");
        if (typ == "limit") {
          this.reaction_role_limit = parseInt(num);
          this.reaction_type = {
            name: "limit",
            description:
              "Limits the total number of roles one can pick up from this message"
          };
        }
        if (typ == "temp") {
          this.reaction_role_limit = Math.floor(parseInt(num) / 60);
          this.reaction_type = {
            name: "temp",
            description: "Roles are assigned for a limited time only."
          };
        }
      }

      this.message_id = row.message_id;
      this.blacklist = row.extra
        ? this.roles.filter(r => row.extra.blacklist.includes(r.id))
        : [];
      this.whitelist = row.extra
        ? this.roles.filter(r => row.extra.whitelist.includes(r.id))
        : [];

      this.message_id = row.messages[0].message_id;
      this.editChannel = row.messages[0].channel_id;
      this.selected_reactions = [];
      this.showpicker = false;
      var open_okay = true;
      row.messages.forEach(m => {
        // Absurd variation selectors
        const emoji =
          this.emoji_from_id(m.reaction_emoji) ||
          this.emoji_from_id(m.reaction_emoji + "\uFE0F");
        if (!emoji) {
          open_okay = false;
          //this.bad_input({message: 'Sorry, due to how emoji works, you cannot edit this message online.'})
          return;
        }
        let found = false;
        this.selected_reactions.forEach((val, idx) => {
          if (open_okay && val.emoji.id == emoji.id) {
            this.selected_reactions[idx].roles.push(
              this.roles.find(x => x.id == m.role_id)
            );
            found = true;
          }
        });
        if (!found) {
          this.selected_reactions.push({
            emoji: emoji,
            roles: [this.roles.find(x => x.id == m.role_id)]
          });
        }
      });
      if (!open_okay) {
        return this.bad_input({
          message:
            "Sorry, this message cannot be edited online. The normal commands still work fine. This is caused by foreign emoji or regional indicators."
        });
      }
      this.largeModal = true;
    },
    openCreateModal() {
      this.reaction_channel = null;
      this.reaction_type = {
        name: "normal",
        description:
          "Hands out roles when you click on them, does what you'd expect"
      };
      this.createModal = true;
      this.rr_content = "";
      this.message_id = null;
      this.blacklist = [];
      this.whitelist = [];
      this.reaction_role_limit = 0;

      this.selected_reactions = [];
      this.showpicker = false;
      setTimeout(() => {
        this.$refs.embed_builder.show_embed = false;
        this.$refs.embed_builder.resetEmbed();
      }, 20);
    },
    selectEmoji(emoji) {
      this.showpicker = false;
      let should_add = true;
      this.selected_reactions.map(x => {
        if (x.emoji.id == emoji.id) {
          should_add = false;
        }
      });
      if (should_add) {
        this.selected_reactions.push({ emoji: emoji, roles: [] });
      }
    },
    non_shit_pairs() {
      let copied_pairs = [];
      this.selected_reactions.map(x => {
        copied_pairs.push({
          emoji: x.emoji.custom ? x.emoji._data.custom_id : x.emoji.native,
          roles: x.roles
        });
      });
      return copied_pairs;
    },
    splice_selected(idx) {
      this.selected_reactions.splice(idx, 1);
    },
    createRR() {
      if (
        this.create_mode === 0 &&
        !this.rr_content &&
        Object.keys(this.$refs.embed_builder.full_json).length < 2
      ) {
        return this.bad_input({
          message: "Please fill in either the content or some embed fields"
        });
      }
      if (
        this.create_mode === 1 &&
        (!this.message_id ||
          this.message_id < 0 ||
          Number(this.message_id) == NaN)
      ) {
        return this.bad_input({ message: "Please enter a valid message ID" });
      }
      if (!this.reaction_channel) {
        return this.bad_input({ message: "Please select a channel" });
      }
      if (
        this.reaction_type === "limit" &&
        (!this.reaction_role_limit ||
          this.reaction_role_limit < 1 ||
          this.reaction_role_limit > 250)
      ) {
        return this.bad_input({
          message: "Please select a limit between 1 and 250"
        });
      }
      if (this.selected_reactions.length == 0) {
        return this.bad_input({ message: "Please add some emojis" });
      }

      return this.axios
        .put(
          "/api/v1/servers/" + this.$route.params.guild_id + "/reactionroles",
          {
            channel_id: this.reaction_channel.id,
            content: this.rr_content,
            embed: this.$refs.embed_builder.full_json,
            pairs: this.non_shit_pairs(),
            whitelist: this.whitelist,
            blacklist: this.blacklist,
            reaction_role_limit: this.reaction_role_limit,
            mode: this.create_mode,
            type: this.reaction_type,
            message_id: this.message_id
          }
        )
        .then(() => {
          this.createModal = false;
          setTimeout(
            () =>
              this.axios
                .get(
                  "/api/v1/servers/" +
                    this.$route.params.guild_id +
                    "/reactionroles"
                )
                .then(response => {
                  this.reactionroles = response.data.rr;
                }),
            2000
          );
        });
    },
    patchRR() {
      if (
        this.reaction_type === "limit" &&
        (!this.reaction_role_limit ||
          this.reaction_role_limit < 1 ||
          this.reaction_role_limit > 250)
      ) {
        return this.bad_input({
          message: "Please select a limit between 1 and 250"
        });
      }
      if (this.selected_reactions.length == 0) {
        return this.bad_input({ message: "Please add some emojis" });
      }
      var changed = false;
      var readonly = false;
      var embed_cleared = false;
      var new_embed = this.$refs.embed_builder.full_json
      if (this.rr_old_data.readonly == false){
      var old_rr_content = this.rr_old_data.message.content
      if (old_rr_content != this.rr_content){ changed = true;}
      if(changed!=true){
      var is_new_empty = Object.keys(this.$refs.embed_builder.full_json).length == 1 && Object.keys(this.$refs.embed_builder.full_json)[0] == 'color'
      var is_old_empty = this.rr_old_data.message.embeds.length == 0

      if(is_new_empty == true){new_embed = null}

      if(is_old_empty!=true && is_new_empty == true){
      embed_cleared = true;
      }

      if (is_old_empty!= true || is_new_empty !=true){
        if(this.rr_old_data.message.embeds[0] && this.rr_old_data.message.embeds[0].hasOwnProperty('author')){
            var emptyvalue;
            if(this.rr_old_data.message.embeds[0]['author'].hasOwnProperty('proxy_icon_url')){
            delete this.rr_old_data.message.embeds[0]['author']['proxy_icon_url'];
            }
            if(!this.rr_old_data.message.embeds[0]['author'].hasOwnProperty('icon_url')){
                this.rr_old_data.message.embeds[0]['author']['icon_url']=emptyvalue;
            }
            if(!this.rr_old_data.message.embeds[0]['author'].hasOwnProperty('url')){
                this.rr_old_data.message.embeds[0]['author']['url']=emptyvalue;
            }
         }
        var eq = _.isEqual(this.rr_old_data.message.embeds[0],this.$refs.embed_builder.full_json)
        if(eq === false){changed = true}
        }

      }else{
      var is_new_empty = Object.keys(this.$refs.embed_builder.full_json).length == 1 && Object.keys(this.$refs.embed_builder.full_json)[0] == 'color'
      if(is_new_empty == true){new_embed = null}
      }

      if(changed === false){readonly = true}

}
      return this.axios
        .patch(
          "/api/v1/servers/" + this.$route.params.guild_id + "/reactionroles",
          {
            channel_id: this.editChannel,
            pairs: this.non_shit_pairs(),
            whitelist: this.whitelist.map(r => r.id),
            blacklist: this.blacklist.map(r => r.id),
            reaction_role_limit: this.reaction_role_limit,
            type: this.reaction_type,
            message_id: this.message_id,
            message_content: this.rr_content,
            embed: new_embed,
            readonly: readonly,
            flags: this.content_flags,
            hide_embed: embed_cleared,
          }
        )
        .then(r => {
          this.largeModal = false;
          setTimeout(
            () =>
              this.axios
                .get(
                  "/api/v1/servers/" +
                    this.$route.params.guild_id +
                    "/reactionroles"
                )
                .then(response => {
                  this.reactionroles = response.data.rr;
                  for (let [i, e] of this.reactionroles.entries()) {
                    if (e.message_id === this.message_id) {
                      if (e.extra) {
                        e.extra.blacklist = this.blacklist.map(r => r.id);
                        e.extra.whitelist = this.whitelist.map(r => r.id);
                        e.extra.type = this.reaction_type.name;
                        if (this.reaction_role_limit != 0) {
                          let dur = parseInt(this.reaction_role_limit);
                          if (this.reaction_type.name == "temp") {
                            dur *= 60;
                          }
                          e.extra.type += ` ${dur}`;
                        }
                      }
                    }
                  }
                }),
            2000
          );
        });
    },
    deleteRR() {
      this.axios
        .delete(
          "/api/v1/servers/" +
            this.$route.params.guild_id +
            "/reactionroles/" +
            this.message_id
        )
        .then(this.closeModals());
      for (let [i, e] of this.reactionroles.entries()) {
        if (e.message_id === this.message_id) {
          this.reactionroles.splice(i, 1);
        }
      }
    },
    closeModals() {
      this.largeModal = false;
      this.createModal = false;
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/reactionroles")
      .then(r => {
        this.custom_emoji = r.data.emoji;
        this.reactionroles = r.data.rr;
        this.roles = r.data.roles;
        this.channels = r.data.channels;
        this.top_position = r.data.position;
        this.is_patron = r.data.is_patron;
      });
  },
  computed: {
    usable_roles() {
      return this.roles.filter(x => x.position < this.top_position);
    },
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    }
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/reactionroles")
      .then(r => {
        this.custom_emoji = r.data.emoji;
        this.reactionroles = r.data.rr;
        this.roles = r.data.roles;

        this.channels = r.data.channels;
        this.top_position = r.data.position;
        this.is_patron = r.data.is_patron;

        let temp = {};

        this.custom_emoji.map(x => {
          temp[x.id] = true;
          this.custom.push({
            name: x.name,
            short_names: [x.id],
            id: x.id,
            imageUrl: x.animated
              ? `https://cdn.discordapp.com/emojis/${x.id}.gif?v=1`
              : `https://cdn.discordapp.com/emojis/${x.id}.png?v=1`,
            custom: true,
            custom_id: x.id
          });
        });
        this.reactionroles.forEach(m => {
          m.messages.forEach(r => {
            if (!isNaN(r.reaction_emoji) && temp[r.reaction_emoji] != true) {
              this.custom.push({
                name: "unknown",
                short_names: [r.reaction_emoji],
                id: r.reaction_emoji,
                imageUrl: `https://cdn.discordapp.com/emojis/${r.reaction_emoji}.png?v=1`,
                custom: true,
                custom_id: r.reaction_emoji
              });
            }
          });
        });
        this.index = new EmojiIndex(data, {
          custom: this.custom
        });
        this.loading = false;
        this.$Progress.finish();
      });
  }
};
</script>

<style lang="scss">
#dataTable {
  width: 100%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>

<style>
.emoji-mart {
  background-color: #3a4149;
  border: 1px solid #202225;
}

.emoji-mart-category .emoji-mart-category-label span {
  color: #eee;
  background-color: #3a4149;
  border-radius: 5px;
}
.emoji-mart-search input {
  background-color: #515b65;
  color: #eee;
  border: 1px solid #202225;
}

.emoji-mart-bar {
  border: 0 solid #202225;
}

.emoji-mart-preview-data .emoji-mart-preview-name {
  color: #eee;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #515b65;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  opacity: 1;
}

.emoji-mart-emoji.displayed-emoji {
  padding: 0px 12px;
}

.toolsContainer {
  margin-right: 21px;
  position: relative;
  -webkit-box-flex: 0;
  flex: 0 0 36px;
  display: flex;
}
.closebutton {
  border-color: #aaa;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32px;
  flex: 0 0 32px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.closebutton:hover {
  background-color: rgba(114, 118, 125, 0.3);
  cursor: pointer;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
</style>
